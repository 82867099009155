import React, { useContext } from 'react';
import Title from '../resources/Title';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DynamicSelect from '../resources/DynamicSelect';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { MainContext } from '../../context/MainContext';
import * as Constants from '../../components/api/constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Generate Order Data
function createData(id, name, status, state, send) {
  return { id, name, status, state, send };
}

const selectDataArray1 = [
  {
    id: 0,
    value: ''    
  },
  {
    id: 1,
    value: ''    
  },
  {
    id: 2,
    value: ''    
  },
  {
    id: 3,
    value: ''    
  },
  {
    id: 4,
    value: ''    
  },
  {
    id: 5,
    value: ''    
  },
  {
    id: 6,
    value: ''    
  },
  {
    id: 7,
    value: ''    
  },
  {
    id: 8,
    value: ''    
  },
  {
    id: 9,
    value: ''    
  },
  {
    id: 10,
    value: ''    
  },
  {
    id: 11,
    value: ''    
  },
  {
    id: 12,
    value: ''    
  },
  {
    id: 13,
    value: ''    
  },
  {
    id: 14,
    value: ''    
  },
  {
    id: 15,
    value: ''    
  },
  {
    id: 16,
    value: ''    
  }
];

const selectDataArray2 = [
  {
    id: 0,
    value: ''    
  },
  {
    id: 1,
    value: ''    
  },
  {
    id: 2,
    value: ''    
  },
  {
    id: 3,
    value: ''    
  },
  {
    id: 4,
    value: ''    
  },
  {
    id: 5,
    value: ''    
  },
  {
    id: 6,
    value: ''    
  },
  {
    id: 7,
    value: ''    
  },
  {
    id: 8,
    value: ''    
  },
  {
    id: 9,
    value: ''    
  },
  {
    id: 10,
    value: ''    
  },
  {
    id: 11,
    value: ''    
  },
  {
    id: 12,
    value: ''    
  },
  {
    id: 13,
    value: ''    
  },
  {
    id: 14,
    value: ''    
  },
  {
    id: 15,
    value: ''    
  }
];

const buttonWidth = '20%';
let deviceId = 0;

const clmtData = [];
var a = 10;
var counter = 1;
while (a <= 60) {
  clmtData.push({
    id: counter,
    name: a   
  });
  a++;
  counter++;
}

const lshdData = [];
a = 1;
counter = 1;
while (a <= 7) {
  lshdData.push({
    id: counter,
    name: a   
  });
  a++;
  counter++;
}

const fcycleData = [
  {
    id: '1',
    name: '1'    
  },
  {
    id: '2',
    name: '2'    
  },
  {
    id: '3',
    name: '3'    
  },
  {
    id: '4',
    name: '4'    
  },
  {
    id: '5',
    name: '6'    
  },
  {
    id: '6',
    name: '8'    
  },
  {
    id: '7',
    name: '12'    
  },
  {
    id: '8',
    name: '24'    
  }
];

const veleData = [
  {
    id: '1',
    name: 'On Off'    
  },
  {
    id: '2',
    name: 'STEP'    
  },
  {
    id: '3',
    name: 'VARI'    
  }
];

const vmaxData = [];
a = 3;
counter = 1;
while (a <= 30) {
  vmaxData.push({
    id: counter,
    name: a   
  });
  a++;
  counter++;
}

const gasData = [
  {
    id: '1',
    name: '0 = Norm'    
  },
  {
    id: '2',
    name: '1 = 10 min cool down'    
  }
];

const prmeData = [
  {
    id: '1',
    name: '10 second filt'    
  },
  {
    id: '2',
    name: '2 minute filt'    
  },
  {
    id: '3',
    name: '2 minute all'    
  },
  {
    id: '4',
    name: '4 minute all'    
  }
];

const onoffData = [
  {
    id: '1',
    name: 'On'    
  },
  {
    id: '2',
    name: 'Off'    
  }
];

const tadjData = [];
a = -2.0;
counter = 1;
while (a <= 2.1) {
  tadjData.push({
    id: counter,
    name: a.toFixed(1)   
  });
  a = a + 0.1;
  counter++;
}

const llmData = [];
a = 1;
counter = 1;
while (a <= 5) {
  llmData.push({
    id: counter,
    name: a   
  });
  a++;
  counter++;
}

const unitData = [
  {
    id: '1',
    name: '°C'    
  },
  {
    id: '2',
    name: '°F'    
  }
];

const ahysData = [];
a = 0;
counter = 1;
while (a <= 200) {
  ahysData.push({
    id: counter,
    name: a   
  });
  a = a + 10;
  counter++;
}

const omaxData = [];
a = 1;
counter = 1;
while (a <= 24) {
  omaxData.push({
    id: counter,
    name: a   
  });
  a++;
  counter++;
}

const serData = [];
serData.push({
  id: 1,
  name: '0 = disabled'   
});
serData.push({
  id: 2,
  name: '1 wks'   
});
a = 2;
counter = 3;
while (a <= 52) {
  serData.push({
    id: counter,
    name: a   
  });
  a++;
  counter++;
}

const thysData = [];
a = 0.2;
counter = 0.1;
while (a <= 2.0) {
  thysData.push({
    id: counter.toFixed(1), 
    name: a.toFixed(1)    
  });
  a = parseFloat((a + 0.1).toFixed(1)); 
  counter++;
}

const dbgnData = [];
a = 10;
counter = 1;
while (a >= -20) {
  dbgnData.push({
    id: counter,
    name: a  
  });
  a = a - 1;
  counter++;
}

const dendData = [];
a = 5;
counter = 1;
while (a <= 45) {
  dendData.push({
    id: counter,
    name: a  
  });
  a++;;
  counter++;
}

const dcmpData = [];
a = 20;
counter = 1;
while (a <= 150) {
  dcmpData.push({
    id: counter,
    name: a  
  });
  a++;;
  counter++;
}

const dmaxData = [];
a = 1;
counter = 1;
while (a <= 15) {
  dmaxData.push({
    id: counter,
    name: a  
  });
  a++;;
  counter++;
}

const cmaxData = [];
a = 70;
counter = 1;
while (a <= 110) {
  cmaxData.push({
    id: counter,
    name: a  
  });
  a++;;
  counter++;
}

const pminData = [];
a = 20;
counter = 1;
while (a <= 100) {
  pminData.push({
    id: counter,
    name: a  
  });
  a++;;
  counter++;
}

const pmaxData = [];
a = 50;
counter = 1;
while (a <= 100) {
  pmaxData.push({
    id: counter,
    name: a  
  });
  a++;;
  counter++;
}

const handleSelectChange = (id, selectedValue) =>{
  selectDataArray1[id].value = selectedValue;
}

const handleSelectChange2 = (id, selectedValue) =>{
  if (id === 4) {
    selectDataArray2[id].value = selectedValue * 10;
  } else {
    selectDataArray2[id].value = selectedValue;
  }
}

export default function EEPROMOEMMenu() {
  const state = useContext(MainContext).state;
  const r3 = state.R3.split(',');
  const r6 = state.R6.split(',');
  const r7 = state.R7.split(',');
  const re = state.RE.split(',');

  deviceId = state.selectedDevice;

  const sendCommand = (command, value) => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ DeviceId: deviceId,
                              Command: command, 
                              Value: value.toString() }),  
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiSendCommand, requestOptions) 
        .then((response) => {
            if (response.ok) {
              toast.success('Command sent successfully. Refreshing...', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
              setTimeout(function() {
                state.refreshDeviceRF(deviceId);
              }, 6000);

              setTimeout(function() {
                state.getUserDeviceRf(deviceId);
              }, 12000);
            } else {
              toast.error('Send failed. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }

  const onClickRow1 = (id) => {
    if (id === 0) {
      sendCommand('clmt', selectDataArray1[id].value);
    } else if (id === 1) {
      sendCommand('lshd', selectDataArray1[id].value);
    } else if (id === 2) {
      sendCommand('sani', selectDataArray1[id].value);
    } else if (id === 3) {
      sendCommand('cjet', selectDataArray1[id].value);
    } else if (id === 4) {
      sendCommand('fcyc', selectDataArray1[id].value);
    } else if (id === 5) {
      sendCommand('vele', selectDataArray1[id].value);
    } else if (id === 6) {
      sendCommand('vmax', selectDataArray1[id].value);
    } else if (id === 7) {
      sendCommand('gas', selectDataArray1[id].value);
    } else if (id === 8) {
      sendCommand('prme', selectDataArray1[id].value);
    } else if (id === 9) {
      sendCommand('huse', selectDataArray1[id].value);
    } else if (id === 10) {
      sendCommand('tadj', selectDataArray1[id].value);
    } else if (id === 11) {
      sendCommand('1llm', selectDataArray1[id].value);
    } else if (id === 12) {
      sendCommand('2llm', selectDataArray1[id].value);
    } else if (id === 13) {
      sendCommand('3llm', selectDataArray1[id].value);
    } else if (id === 14) {
      sendCommand('unit', selectDataArray1[id].value);
    } else if (id === 15) {
      sendCommand('ahys', selectDataArray1[id].value);
    } else if (id === 16) {
      sendCommand('omax', selectDataArray1[id].value);
    }
  }

  const onClickRow2 = (id) => {
    if (id === 0) {
      sendCommand('c24', selectDataArray2[id].value);
    } else if (id === 1) {
      sendCommand('1ser', selectDataArray2[id].value);
    } else if (id === 2) {
      sendCommand('2ser', selectDataArray2[id].value);
    } else if (id === 3) {
      sendCommand('3ser', selectDataArray2[id].value);
    } else if (id === 4) {
      sendCommand('thys', selectDataArray2[id].value);
    } else if (id === 5) {
      sendCommand('dbgn', selectDataArray2[id].value);
    } else if (id === 6) {
      sendCommand('dend', selectDataArray2[id].value);
    } else if (id === 7) {
      sendCommand('dcmp', selectDataArray2[id].value);
    } else if (id === 8) {
      sendCommand('dmax', selectDataArray2[id].value);
    } else if (id === 9) {
      sendCommand('dele', selectDataArray2[id].value);
    } else if (id === 10) {
      sendCommand('dpmp', selectDataArray2[id].value);
    } else if (id === 11) {
      sendCommand('cmax', selectDataArray2[id].value);
    } else if (id === 12) {
      sendCommand('pmin', selectDataArray2[id].value);
    } else if (id === 13) {
      sendCommand('pflt', selectDataArray2[id].value);
    } else if (id === 14) {
      sendCommand('phtr', selectDataArray2[id].value);
    } else if (id === 15) {
      sendCommand('pmax', selectDataArray2[id].value);
    }
  }

  const rows = [
    createData(0, 'C.LMT', 'N/A', <DynamicSelect arrayOfData={clmtData} onSelectChange={handleSelectChange} id={0} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow1(0)}}>Send</Button>),
    createData(1, 'L.SHD', 'N/A', <DynamicSelect arrayOfData={lshdData} onSelectChange={handleSelectChange} id={1} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow1(1)}}>Send</Button>),
    createData(2, 'SANI', 'N/A', <DynamicSelect arrayOfData={onoffData} onSelectChange={handleSelectChange} id={2} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow1(2)}}>Send</Button>),
    createData(3, 'C.JET', 'N/A', <DynamicSelect arrayOfData={onoffData} onSelectChange={handleSelectChange} id={3} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow1(3)}}>Send</Button>),
    createData(4, 'F.CYC', 'N/A', <DynamicSelect arrayOfData={fcycleData} onSelectChange={handleSelectChange} id={4} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow1(4)}}>Send</Button>),
    createData(5, 'V.ELE', 'N/A', <DynamicSelect arrayOfData={veleData} onSelectChange={handleSelectChange} id={5} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow1(5)}}>Send</Button>),
    createData(6, 'V.MAX', 'N/A', <DynamicSelect arrayOfData={vmaxData} onSelectChange={handleSelectChange} id={6} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow1(6)}}>Send</Button>),
    createData(7, 'GAS', 'N/A', <DynamicSelect arrayOfData={gasData} onSelectChange={handleSelectChange} id={7} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow1(7)}}>Send</Button>),
    createData(8, 'PRME', 'N/A', <DynamicSelect arrayOfData={prmeData} onSelectChange={handleSelectChange} id={8} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow1(8)}}>Send</Button>),
    createData(9, 'H.USE', 'N/A', <DynamicSelect arrayOfData={onoffData} onSelectChange={handleSelectChange} id={9} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow1(9)}}>Send</Button>),
    createData(10, 'T.ADJ', 'N/A', <DynamicSelect arrayOfData={tadjData} onSelectChange={handleSelectChange} id={10} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow1(10)}}>Send</Button>),
    createData(11, '1.LLM', 'N/A', <DynamicSelect arrayOfData={llmData} onSelectChange={handleSelectChange} id={11} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow1(11)}}>Send</Button>),
    createData(12, '2.LLM', 'N/A', <DynamicSelect arrayOfData={llmData} onSelectChange={handleSelectChange} id={12} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow1(12)}}>Send</Button>),
    createData(13, '3.LLM', 'N/A', <DynamicSelect arrayOfData={llmData} onSelectChange={handleSelectChange} id={13} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow1(13)}}>Send</Button>),
    createData(14, 'UNIT', 'N/A', <DynamicSelect arrayOfData={unitData} onSelectChange={handleSelectChange} id={14} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow1(14)}}>Send</Button>),
    createData(15, 'A.HYS', 'N/A', <DynamicSelect arrayOfData={ahysData} onSelectChange={handleSelectChange} id={15} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow1(15)}}>Send</Button>),
    createData(16, 'O.MAX', 'N/A', <DynamicSelect arrayOfData={omaxData} onSelectChange={handleSelectChange} id={16} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow1(16)}}>Send</Button>),
  ];
  
  const rows2 = [
    createData(0, 'C.24', 'N/A', <DynamicSelect arrayOfData={onoffData} onSelectChange={handleSelectChange2} id={0} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow2(0)}}>Send</Button>),
    createData(1, '1.SER', 'N/A', <DynamicSelect arrayOfData={serData} onSelectChange={handleSelectChange2} id={1} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow2(1)}}>Send</Button>),
    createData(2, '2.SER', 'N/A', <DynamicSelect arrayOfData={serData} onSelectChange={handleSelectChange2} id={2} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow2(2)}}>Send</Button>),
    createData(3, '3.SER', 'N/A', <DynamicSelect arrayOfData={serData} onSelectChange={handleSelectChange2} id={3} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow2(3)}}>Send</Button>),
    createData(4, 'T.HYS', 'N/A', <DynamicSelect arrayOfData={thysData} onSelectChange={handleSelectChange2} id={4} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow2(4)}}>Send</Button>),
    createData(5, 'D.BGN', 'N/A', <DynamicSelect arrayOfData={dbgnData} onSelectChange={handleSelectChange2} id={5} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow2(5)}}>Send</Button>),
    createData(6, 'D.END', 'N/A', <DynamicSelect arrayOfData={dendData} onSelectChange={handleSelectChange2} id={6} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow2(6)}}>Send</Button>),
    createData(7, 'D.CMP', 'N/A', <DynamicSelect arrayOfData={dcmpData} onSelectChange={handleSelectChange2} id={7} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow2(7)}}>Send</Button>),
    createData(8, 'D.MAX', 'N/A', <DynamicSelect arrayOfData={dmaxData} onSelectChange={handleSelectChange2} id={8} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow2(8)}}>Send</Button>),
    createData(9, 'D.ELE', 'N/A', <DynamicSelect arrayOfData={dbgnData} onSelectChange={handleSelectChange2} id={9} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow2(9)}}>Send</Button>),
    createData(10, 'D.PMP', 'N/A', <DynamicSelect arrayOfData={dbgnData} onSelectChange={handleSelectChange2} id={10} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow2(10)}}>Send</Button>),
    createData(11, 'C.MAX', 'N/A', <DynamicSelect arrayOfData={cmaxData} onSelectChange={handleSelectChange2} id={11} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow2(11)}}>Send</Button>),
    createData(12, 'P.MIN', 'N/A', <DynamicSelect arrayOfData={pminData} onSelectChange={handleSelectChange2} id={12} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow2(12)}}>Send</Button>),
    createData(13, 'P.FLT', 'N/A', <DynamicSelect arrayOfData={pminData} onSelectChange={handleSelectChange2} id={13} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow2(13)}}>Send</Button>),
    createData(14, 'P.HTR', 'N/A', <DynamicSelect arrayOfData={pminData} onSelectChange={handleSelectChange2} id={14} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow2(14)}}>Send</Button>),
    createData(15, 'P.MAX', 'N/A', <DynamicSelect arrayOfData={pmaxData} onSelectChange={handleSelectChange2} id={15} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickRow2(15)}}>Send</Button>),
  ];

  rows[0].status = r3[1];
  rows[1].status = r3[17];
  
  if (r7[3] === '0') rows[2].status = 'On';
  if (r7[3] === '1') rows[2].status = 'Off';

  if (r7[6] === '0') rows[3].status = 'On';
  if (r7[6] === '1') rows[3].status = 'Off';

  rows[4].status = r6[7];

  if (r7[7] === '0') rows[5].status = 'On/Off';
  if (r7[7] === '1') rows[5].status = 'Step';
  if (r7[7] === '2') rows[5].status = 'Vari';

  rows[6].status = r7[22];
  rows[7].status = r6[27];

  if (r6[24] === '0') rows[8].status = '10secF';
  if (r6[24] === '1') rows[8].status = '2minF';
  if (r6[24] === '2') rows[8].status = '2minA';
  if (r6[24] === '3') rows[8].status = '4minA';

  if (r7[24] === '0') rows[9].status = 'On';
  if (r7[24] === '1') rows[9].status = 'Off';

  rows[10].status = (r3[27]/10.0).toFixed(1);

  rows[11].status = r3[3];
  rows[12].status = r3[4];
  rows[13].status = r3[5];

  if (r7[2] === '0') rows[14].status = '°C';
  if (r7[2] === '1') rows[14].status = '°F';

  rows[15].status = (r7[23]/10.0).toFixed(1);
  rows[16].status = r7[4];

  if (r7[5] === '0') rows2[0].status = 'On';
  if (r7[5] === '1') rows2[0].status = 'Off';

  rows2[1].status = r7[19];
  rows2[2].status = r7[20];
  rows2[3].status = r7[21];
  rows2[4].status = (r7[31]/10.0).toFixed(1) + ' °C';
  rows2[5].status = re[25];
  rows2[6].status = re[26];
  rows2[7].status = re[27];
  rows2[8].status = re[28];
  rows2[9].status = re[29];
  rows2[10].status = re[30];
  rows2[11].status = re[39];
  if (re[39] === undefined) rows2[11].status = '...';
  rows2[12].status = r7[27];
  rows2[13].status = r7[28];
  rows2[14].status = r7[29];
  rows2[15].status = r7[30];

  return (
    <React.Fragment>
      <Title>OEM Menu</Title>
        <Container maxWidth="lg" sx={{ flexDirection: 'row', paddingHorizontal: 5, mt: 2 }}>
          <Grid container spacing={3}> 
          <Grid item sx={{ flex: 1, flexDirection: 'column' }} xs={12} md={6} lg={6}>
            <Table size="small">
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{row.state}</TableCell>
                    <TableCell>{row.send}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          
          <Grid item sx={{ flex: 1, flexDirection: 'column' }} xs={12} md={6} lg={6}>
            <Table size="small" >
              <TableBody>
                {rows2.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{row.state}</TableCell>
                    <TableCell>{row.send}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
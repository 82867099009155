import React, { useContext } from 'react';
import Title from '../resources/Title';
import { MainContext } from '../../context/MainContext';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import * as Constants from '../api/constants'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function createData(id, property, value) {
  return { id, property, value };
}

const rows = [
  createData(0, 'Command Echo', 'N/A',),
  createData(1, 'Controller Model', 'N/A',),
  createData(2, 'Controller Software Version', 'N/A',),
  createData(3, 'SSIG Detect Enabled', 'N/A',),
  createData(4, 'SSIG Found', 'N/A',),
  createData(5, 'SSIG Connected', 'N/A',),
  createData(6, 'SSIG IP Address', 'N/A',),
  createData(7, 'SSIG Host Address', 'N/A',),
  createData(8, 'SSIG Connection Up time', 'N/A',),
  createData(9, 'SSIG Fail Counter', 'N/A',),
  createData(10, 'SSIG Last Error', 'N/A',),
  createData(11, 'Heat Signal', 'N/A',)
];

export default function HistorySNWifiR2() {
  const state = useContext(MainContext).state;
  const snWifiR2 = state.snWifiR2?.split(',') ?? [];
  let deviceId = state.selectedDevice;

  const sendCommand = (command, value) => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ DeviceId: deviceId,
                              Command: command, 
                              Value: value }),  
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiSendCommand, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              toast.success('Command sent successfully. Refreshing...', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
              setTimeout(function() {
                state.refreshDeviceRF(deviceId);
              }, 6000);

              setTimeout(function() {
                state.getUserDeviceRf(deviceId);
              }, 12000);
            } else {
              toast.error('Send failed. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }

  const onClickListenerUpdate = () => {
    sendCommand('SN-WIFI-R2', '');
  }

  const onClickListenerOnOff = () => {
    if (snWifiR2[3] === '1') {
      sendCommand('SN-WIFI-SSIG:', '0');
    } else {
      sendCommand('SN-WIFI-SSIG:', '1');
    }
  }

  if (snWifiR2.length > 2) {
    rows[0].value = snWifiR2[0];
    rows[1].value = snWifiR2[1];
    rows[2].value = snWifiR2[2];
    rows[3].value = snWifiR2[3];
    rows[4].value = snWifiR2[4];
    rows[5].value = snWifiR2[5];
    rows[6].value = snWifiR2[6];
    rows[7].value = snWifiR2[7];
    rows[8].value = snWifiR2[8];
    rows[9].value = snWifiR2[9];
    rows[10].value = snWifiR2[10];
    rows[11].value = snWifiR2[11];
  } else {
    rows[0].value = 'N/A';
    rows[1].value = 'N/A';
    rows[2].value = 'N/A';
    rows[3].value = 'N/A';;
    rows[4].value = 'N/A';
    rows[5].value = 'N/A';
    rows[6].value = 'N/A';
    rows[7].value = 'N/A';
    rows[8].value = 'N/A';
    rows[9].value = 'N/A';
    rows[10].value = 'N/A';
    rows[11].value = 'N/A';
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Title>SN-WIFI-R2 Data</Title>
      <Table size="small" sx={{ mb: 3 }} >
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.property}</TableCell>
              <TableCell>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button variant="outlined" onClick={() => onClickListenerUpdate()}>Update</Button>
      <br />
      <Button variant="outlined" onClick={() => onClickListenerOnOff()}>{snWifiR2[3] === '1' ? 'Turn Off' : 'Turn On'}</Button>
    </React.Fragment>
  );
}